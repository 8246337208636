import './global/styles.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import App from './App';
import theme from './theme';
import reportWebVitals, { sendToGoogleAnalytics } from './reportWebVitals';

import { RootStoreContext } from './global/storeContext';
import { rootStore } from './stores/rootStore';

ReactDOM.render(
    <React.StrictMode>
        <RootStoreContext.Provider value={rootStore}>
            <ThemeProvider theme={theme}>
                <BrowserRouter basename=''>
                    <App />
                </BrowserRouter>
            </ThemeProvider>
        </RootStoreContext.Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendToGoogleAnalytics);
