import React from 'react';
import '../styles.css';
import { observer } from 'mobx-react-lite';
import { MainLayout } from '../../layouts/main';
import { Avatar, Box, Button, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
export const HomePage: React.FC = observer(() => {
    const TypographyContent: React.FC = ({ children }) => (
        <Typography
            justifyItems='center'
            align='justify'
            variant='body1'
            gutterBottom
            style={{ paddingBottom: '10px' }}
        >
            {children}
        </Typography>
    );

    const TypographyTitle: React.FC = ({ children }) => (
        <Typography
            style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
            gutterBottom
            variant='subtitle2'
            display='block'
            color='secondary'
        >
            {children}
        </Typography>
    );
    return (
        <MainLayout>
            <Grid container spacing={2}>
                <Grid item xs={4} padding={2} alignItems='center' justifyItems='center'>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingTop: '15px',
                            paddingBottom: '5px',
                        }}
                    >
                        <Avatar
                            // variant='rounded'
                            alt='Stanley Diji'
                            src='img/headshot.jpg'
                            sx={{ width: 200, height: 200 }}
                            imgProps={{ style: { objectPosition: 'top' } }}
                        />
                    </div>

                    <Typography
                        style={{
                            textTransform: 'uppercase',
                            paddingTop: '5px',
                            fontWeight: 'bold',
                        }}
                    >
                        Stanley C. Diji
                    </Typography>

                    <Typography
                        gutterBottom
                        variant='body2'
                        style={{
                            verticalAlign: 'middle',
                            display: 'inline-flex',
                            paddingBottom: '15px',
                        }}
                        color='secondary'
                    >
                        📍 Calgary, AB
                    </Typography>

                    <Button
                        variant='contained'
                        startIcon={<MailOutlineIcon />}
                        fullWidth
                        href='mailto:dijistanley@gmail.com'
                    >
                        Get in touch
                    </Button>
                    <Divider variant='middle' />
                    <Box sx={{ m: 2 }} paddingTop={3}>
                        <TypographyTitle>Weekend Projects</TypographyTitle>
                        <Stack direction='column' spacing={1}>
                            <a
                                href='http://smard.io'
                                className='App-link'
                                rel='noreferrer'
                                target='_blank'
                            >
                                smard.io
                            </a>
                            <a
                                href='https://realhaus.ca'
                                rel='noreferrer'
                                target='_blank'
                                className='App-link'
                            >
                                realhaus.ca
                            </a>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={8}>
                    <Grid container rowSpacing={2} padding={2}>
                        <Grid item xs={12} paddingRight={1}>
                            <Grid
                                container
                                direction='row'
                                justifyContent='flex-end'
                                alignItems='center'
                            >
                                <Grid item style={{ textAlign: 'center' }}>
                                    <IconButton
                                        href='https://linkedin.com/in/dijistanley'
                                        target='_blank'
                                    >
                                        <LinkedInIcon fontSize='large' />
                                    </IconButton>
                                    <IconButton
                                        href='https://twitter.com/stanleydiji'
                                        target='_blank'
                                    >
                                        <TwitterIcon fontSize='large' />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <div>
                                <TypographyContent>
                                    Hi, I’m Stanley. An engineering leader with an extensive
                                    background in software engineering.
                                </TypographyContent>
                                <TypographyContent>
                                    Driven by my zeal to lead, learn and innovate, my primary focus
                                    is on providing long-term technical solutions to solving
                                    real-world problems.
                                </TypographyContent>
                                <TypographyContent>
                                    Currently, I am helping build industrial construction
                                    initiatives at{' '}
                                    <a href='https://www.autodesk.ca/' className='App-link'>
                                        Autodesk
                                    </a>
                                </TypographyContent>
                                <TypographyContent>
                                    <b>My Interests:</b> Technology. Space. Real Estate. Health
                                </TypographyContent>
                            </div>
                            <Grid container spacing={2} style={{ paddingTop: '20px' }}>
                                <Grid item xs={6}>
                                    <TypographyTitle>Work</TypographyTitle>

                                    <Stack direction='column' spacing={1}>
                                        <Typography variant='body2'>Autodesk</Typography>
                                        <Typography variant='body2'>Vena</Typography>
                                        <Typography variant='body2'>
                                            National Oilwell Varco
                                        </Typography>
                                        <Typography variant='body2'>IBM Canada</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={6}>
                                    <TypographyTitle>Education</TypographyTitle>
                                    <Stack direction='column' spacing={1}>
                                        <Typography variant='body2'>MBA, Quantic</Typography>
                                        <Typography variant='body2'>
                                            Cert. AI, University of Alberta
                                        </Typography>
                                        <Typography variant='body2'>
                                            BSc. Computer Science, York University
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </MainLayout>
    );
});
