import { Route, Routes } from 'react-router-dom';

import routes, { IRoute } from './routes';

function App() {
    return (
        <div className='App'>
            <Routes>
                {routes.map((route: IRoute, key) => (
                    <Route key={key} path={route.path} element={<route.component />} />
                ))}
            </Routes>
        </div>
    );
}

export default App;
