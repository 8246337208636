import { Theme } from '@mui/material';
import { createStyles, makeStyles, styled } from '@mui/styles';

export const MainContent = styled('div')({
  flex: '1 0 100%',
  marginTop: '50px',
  padding: '35px'
});

export const HeaderName = styled('div')({
  paddingTop: '60px',
  paddingBottom: '20px'
});

export const HeaderNavLinks = styled('div')({
  paddingTop: '20px',
  paddingBottom: '30px',
  '& > a': {
    color: '#343434',
    textTransform: 'uppercase',
    padding: '0px 1em',
    display: 'inline-block',
    fontSize: '12px'
  }
});

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      background: '#f5f8fa !important',
      color: `${theme.palette.common.black} !important`,
      display: 'flex',
      paddingBottom: '40px 25px'
      // zIndex: 1000,
      // height: '100%'
    },
    toolBar: {
      minHeight: 180,
      '& > div': {
        flexGrow: 1,
      },
      '& > div:nth-child(even)': {
        width: '100%'
      }
    },
  }),
);
