import { HomePage } from './pages/home/homePage';

export interface IRoute {
  path: string;
  name: string;
  component: React.FC;
}

export const paths = {
  home: '/',
}
export const pageRoutes: IRoute[] = [
  {
    path: paths.home,
    name: "Home Page",
    component: HomePage,
  }
];

export default pageRoutes;