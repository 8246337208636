import React from 'react';
import { observer } from 'mobx-react-lite';

// import { MainHeader } from './header';
import { MainContent } from './styles';
import { Container, Grid } from '@mui/material';

export const MainLayout: React.FC = observer(({ children }) => {
    return (
        <>
            {/* Body */}
            <div>
                {/* <MainHeader /> */}
                <MainContent>
                    <main>
                        <Container maxWidth='md'>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {children}
                                </Grid>
                            </Grid>
                        </Container>
                    </main>
                </MainContent>
            </div>
            {/* Footer */}
            <div></div>
        </>
    );
});
